import BasePlugin from '../BasePlugin'

export default class DeleteInvestmentRequests extends BasePlugin {
  async execute () {
    let programScenarioId = this.context.getModel().id
    let gridik = this.context.getDashboardComponents()['component_7e86a0e7-6774-438d-8c28-6c9d3073617c'][0]
    let ids = []
    Object.values(gridik.$refs.registry.selected).forEach(function (item) {
      ids.push(item.id)
    })
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/InvestmentProgramScenario/DeleteInvestmentCommand`,
      { 'investmentProgramScenarioId': programScenarioId, 'ids': ids },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getDashboardComponents()['component_7e86a0e7-6774-438d-8c28-6c9d3073617c'][0].loadData()
    })
  }
}
